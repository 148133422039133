<template>
  <CCardBody>
    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Country Filter -->
            <div class="col-lg-3 col-sm-5 mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Country.."
                :value="$store.state.filters.country"
                :options="authCountries"
                :reduce="c => c.value"
                :searchable="false"
                @input="countryFilter"
              />
            </div>

            <!-- Search -->
            <div class="col-md mb-1 mb-md-0 pl-md-0">
              <CInput
                class="mb-0 search"
                type="search"
                placeholder="Search for something else ..."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No hub found!',
      }"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >
      <!-- Actions -->
      <template #actions="{ item, index }">
        <td>
          <CButton
            :to="{ name: 'Edit Hub', params: { id: item.id } }"
            v-c-tooltip="{
              content: 'Edit',
              placement: 'top',
            }"
            size="sm"
            color="info"
            variant="ghost"
            shape="pill"
          >
            <CIcon name="cil-align-left" />
          </CButton>

          <template v-if="item.is_deletable === true">
            <CSpinner v-if="item.deleting" size="sm" color="danger" class="ml-3" />
            <CButton
              v-else
              class="ml-1"
              size="sm"
              color="danger"
              variant="ghost"
              shape="pill"
              @click="deleteItem(item, index)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </template>
        </td>
      </template>

      <!-- id -->
      <template #id="{item}">
        <td>
          {{ item.id }}
        </td>
      </template>

      <!-- Postcode -->
      <template #zip="{ item }">
        <td class="align-middle">
          <CIcon class="mr-1" :content="$options.flagSet[item.country.flag]" size="lg" />
          {{ item.zip }}
        </td>
      </template>

      <!-- Name -->
      <template #name="{item}">
        <td>
          <strong>{{ item.name.toUpperCase() }}</strong>
        </td>
      </template>

      <!-- Scopes Count -->
      <template #scopes_count="{item}">
        <td class="text-center">
          <b>{{ item.scopes_count }}</b>
        </td>
      </template>

      <!-- Couriers Count -->
      <template #couriers_count="{item}">
        <td class="text-center">
          <b>{{ item.couriers_count }}</b>
        </td>
      </template>

      <!-- Created -->
      <template #created_at="{item}">
        <td>{{ moment(item.created_at).format('LL') }}</td>
      </template>
    </CDataTable>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import _debounce from 'lodash/debounce';
import { flagSet } from '@coreui/icons'

export default {
  name: "BackendTable",
  flagSet,
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "actions",         label: "",              filter: false, sorter: false, _style: "min-width: 84px; width: 84px;" },
        { key: "id",              label: "ID",            filter: false, _style: "min-width: 48px; width: 48px;" },
        { key: "zip",             label: "Postcode",      filter: false, sorter: false, _style: "min-width: 96px; width: 96px;" },
        { key: "name",            label: "Name",          filter: false, _style: "min-width: 150px;" },
        { key: "scopes_count",    label: "Scopes Count",  filter: false, sorter: false, _style: "text-align:center" },
        { key: "couriers_count",  label: "Couriers Count",filter: false, sorter: false, _style: "text-align:center" },
        { key: "created_at",      label: "Created",       filter: false, _style: "min-width: 140px; width: 140px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,
      search: null,

      loading: false,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  mounted() {
    this.activePage =  this.queryPage;
    this.getAllItems();

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'Hubs', query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
  },

  methods: {
    getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.COURIER.HUBS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          'order-by': this.orderBy,
          direction: this.direction,
          country: this.$store.state.filters.country,
          search: this.search,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            this.loadedItems = items.data.map((el) => {
              return {
                ...el,
                deleting: false,
                country: this.authCountries.find((c) => c.value === el.country_id),
              };
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },
    async deleteItem(item, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to delete?",
        text: "The hub will be deleted!",
        icon: "warning",
      });

      if (confirm.isConfirmed) {
        const deleteUrl = this.$backend.COURIER.HUBS.DELETE.replace("{id}", item.id);
        item.deleting = true;

        try {
          const { data } = await this.$axios.delete(deleteUrl);
          index !== -1 && this.$delete(this.loadedItems, index);
          this.$toast.success(data.data.message);
        } catch (error) {
          item.deleting = false;

          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        } finally {
          this.$forceUpdate();
        }
      }
    },

    // Filters
    countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.$store.commit("setCountryFilter", null);
        this.search = "";
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}

.search input {
  font-size: 1rem !important;
}
</style>
